import React from "react";
import { Input as InputAntd, Form } from "antd";
import { tuple } from "utils";

const SizeTypes = tuple('large', 'middle', 'small');
type SizeType = typeof SizeTypes[number];

interface NameProps {
  id: string;
  size?: SizeType;
  help?: string;
  disabled: boolean;
  required: boolean;
  className?: string;
  placeholder?: string;
  externalLabel?: string;
  isFormItem?: boolean;
  name?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  defaultValue?: string;
  style?: React.CSSProperties;
  rows?: number;
  maxLength?: number;
  onChange: (e: any) => void;
}

export const InputTextArea = (props: NameProps) => {
  let {
    id,
    defaultValue,
    disabled,
    value,
    error,
    touched,
    size,
    externalLabel,
    isFormItem,
    placeholder,
    name,
    className,
    required,
    style,
    help,
    rows,
    maxLength
  } = props;

  if (isFormItem) {
    return (
      <InputAntd.TextArea
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        size={size || "middle"}
        required={required}
        defaultValue={defaultValue}
        className={className}
        onChange={(e: any) => props.onChange(e)}
      />
    );
  }

  return (
    <Form.Item help={touched || error ? help : ""}
      validateStatus={touched || error ? "error" : "success"}
      label={externalLabel}>
      <InputAntd.TextArea
        disabled={disabled}
        style={{ ...style }}
        name={name}
        size={size || "middle"}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        className={className}
        onChange={(e: any) => props.onChange(e)}
        rows={rows}
        maxLength={maxLength}
      />
    </Form.Item>
  );
};