import { useEffect } from "react";
import { FormCard, Input, Space } from "components";
import { actions } from "states";
import { User } from "types";
import { allowMail, allowLettersAndWS, allowLettersAndDots } from "utils";
import { I18n } from "i18n";

const { useFormik } = actions;

interface NameProps {
    disabled: boolean
    dataUserById?: User | undefined,
    isLoading: boolean
}

const KEYS = [
    { type: 'id', value: 'id', },
    { type: 'name', value: 'name', },
    { type: 'email', value: 'email', },
    { type: 'userName', value: 'username' },
    { type: 'active', value: 'active' }
]

export function Information({ disabled, dataUserById, isLoading }: NameProps) {
    const { values: { user: { createUser } }, onChange } = useFormik();

    useEffect(() => {
        if (dataUserById && !isLoading) {
            let data: any = dataUserById
            KEYS.forEach(({ type, value }: any) => {
                onChange(`user.createUser.${type}`, data[value], true)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUserById, KEYS, isLoading]);

    return (
        <>
            <FormCard
                disabled={disabled}
                title={I18n("basic_user_information")}
                description={I18n("configure_all_general_user_information")} >
                <Space.Columns>
                    <Input
                        defaultValue={dataUserById?.name}
                        size="middle"
                        type="text"
                        help={I18n("invalid_allow_letters_and_ws")}
                        touched={createUser["name-valid"]}
                        error={createUser["name-valid"]}
                        placeholder={`${I18n("name", true)}:*`}
                        onChange={(e) => onChange(`user.createUser.${e.target.name}`, e.target.value,
                            allowLettersAndWS.test(e.target.value))}
                        externalLabel={`${I18n("name")}:*`}
                        required={true}
                        disabled={false}
                        id="name"
                        name="name"
                    />
                </Space.Columns>
                <Space.Columns>
                    <Input
                        defaultValue={dataUserById?.email}
                        size="middle"
                        type="email"
                        help={I18n("invalid_format_email")}
                        touched={createUser["email-valid"]}
                        error={createUser["email-valid"]}
                        placeholder={`${I18n("email", true)}:*`}
                        onChange={(e) => onChange(`user.createUser.${e.target.name}`, e.target.value, allowMail.test(e.target.value))}
                        externalLabel={`${I18n("email", true)}:*`}
                        required={true}
                        disabled={false}
                        id="email"
                        name="email"
                    />
                </Space.Columns>{
                    !dataUserById && <Space.Columns>
                        <Input
                            size="middle"
                            type="password"
                            placeholder={`${I18n("password", true)}:*`}
                            onChange={(e) => onChange(`user.createUser.${e.target.name}`, e.target.value, true)}
                            externalLabel={`${I18n("password", true)}:*`}
                            required={true}
                            disabled={false}
                            id="password"
                            name="password"
                        />
                    </Space.Columns>
                }

                <Space.Columns>
                    <Input
                        defaultValue={dataUserById?.username}
                        size="middle"
                        type="text"
                        help={I18n("allow_letters_and_dots")}
                        touched={createUser["nameUser-valid"]}
                        error={createUser["nameUser-valid"]}
                        placeholder={`${I18n("name_user", true)}:*`}
                        onChange={(e) => onChange(`user.createUser.${e.target.name}`, e.target.value, allowLettersAndDots.test(e.target.value))}
                        externalLabel={`${I18n("name_user", true)}:*`}
                        required={true}
                        disabled={false}
                        id="username"
                        name="userName"
                    />
                </Space.Columns>
            </FormCard>
        </>
    )
}