import { useState } from "react";
import { I18n } from "i18n";
import { Button } from "antd";
import { Toast } from "components";
import { StateUser } from "apollo/users";

interface NameProps {
    onCloseDrawer: () => void,
    userId: string,
    active: boolean
}

export function ButtonActive({ userId, onCloseDrawer, active }: NameProps) {
    const [isLoading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        const { data, error, message } = await StateUser({ _id: userId });
        if (error) {
            Toast.error({ key: "error-save-users", message: I18n(message, true) })
        }
        if (!error && data) {
            onCloseDrawer()
            Toast.success({
                key: "success-save-users",
                message: I18n("your_changes_were_saved_successfully", true),
            });
        }
        setLoading(false);
    };

    return (
        <Button
            loading={isLoading}
            type="primary"
            className="rk-button-primary"
            disabled={isLoading}
            onClick={handleClick}
        >
            {(!active) ? I18n('active', true) : I18n('inactive', true)}
        </Button>
    )
}