import { useEffect } from "react";
import { FormCard, Input, Space, InputTextArea, Toggle } from "components";
import { actions } from "states";
import { Integrator } from "types";
import { I18n } from "i18n";
import { ConfigModules } from "./config-modules";

const { useFormik } = actions;

interface NameProps {
  disabled: boolean
  dataIntegratorById?: Integrator | undefined,
  isLoading: boolean
}

const KEYS = [
  { type: "id", value: "id" },
  { type: "name", value: "name" },
  { type: "description", value: "description" },
  { type: "active", value: "active" },
];

export function Information({ disabled, dataIntegratorById, isLoading }: NameProps) {

  const { values : {integrator : createIntegrator} , onChange} = useFormik();

  console.log(dataIntegratorById + "this is a console log");

  useEffect(() => {
    if (dataIntegratorById && !isLoading) {
        let data: any = dataIntegratorById
        KEYS.forEach(({ type, value }: any) => {
            onChange(`integrator.createIntegrator.${type}`, data[value], true)
        });
    }
}, [dataIntegratorById, KEYS, isLoading]);

  return (
    <>
      <FormCard
        disabled={disabled}
        title={I18n("basic_integrator_information")}
        description={I18n("configure_all_general_integrator_information")}
      >
        <Space.Columns>
          <Input
            defaultValue={dataIntegratorById?.name}
            size="middle"
            type="text"
            placeholder={`${I18n("name", true)}:*`}
            onChange={(e) =>
              onChange(`integrator.createIntegrator.${e.target.name}`, e.target.value, true)
            }
            externalLabel={`${I18n("name", true)}:*`}
            required={true}
            disabled={false}
            id="name"
            name="name"
          />
          <Toggle
            isFormItem={true}
            required={true}
            disabled={false}
            id="active"
            default={dataIntegratorById?.active}
            value={createIntegrator.active}
            externalLabel={I18n("state", true)}
            style={{ width: "100%" }}
            label={`${I18n("state", true)}:*`}
            onChange={(value) => onChange(`integrator.createIntegrator.active`, value, true)}
          />
        </Space.Columns>
        <Space.Columns>
          <InputTextArea
            defaultValue={dataIntegratorById?.description}
            size="middle"
            placeholder={`${I18n("description", true)}:*`}
            onChange={(e) => onChange(`integrator.createIntegrator.${e.target.name}`, e.target.value, true)}
            externalLabel={`${I18n("description", true)}:*`}
            required={true}
            disabled={false}
            id="description"
            name="description"
            maxLength={50}
          />
        </Space.Columns>
      </FormCard>
      <ConfigModules disabled={disabled}></ConfigModules>
    </>
  );
}
