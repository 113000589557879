import { Space, FormCard } from "components";
import { I18n } from "i18n";
import { ModulesList } from "./list-modules"
import { Modules } from "./modules";

interface NameProps{
    disabled: boolean;
}

export function ConfigModules({disabled}:NameProps){
    return(
        <>
        <FormCard
        disabled={disabled}
        title={I18n("modules")}
        description={I18n("select_modules")}
        >
            <Space.Columns>
                <Modules></Modules>
            </Space.Columns>
            <ModulesList/>
        </FormCard>
        </>
    )
}