import { login, resetPassword, setPassword } from "./account";
import { createCustomers } from "./customers";
import { CreateUser } from "./user";
import { CreateDataPoint } from "./data-point";
import { CreateIntegrator } from "./integrator";

let startState = {
  account: {
    login,
    resetPassword,
    setPassword,
  },
  customer: {
    createCustomers,
  },
  user: {
    createUser: CreateUser,
  },
  dataPoint: {
    createDataPoint: CreateDataPoint,
  },
  integrator : {
    createIntegrator: CreateIntegrator ,
  },
};

export const formik = (state: any = startState, action: any) => {
  let type = action.type.split("-");

  if (type.length < 2) return state;

  state = Object.assign({}, state);
  let keys = action.key.split(".");
  let obj = state;

  while (keys.length > 1) {
    if (!obj[keys[0]]) obj[keys[0]] = {};
    obj = obj[keys.shift()];
  }

  obj[keys[0]] = action.value;
  obj[keys[0] + "-valid"] = !action.valid;
  return state;
};
