import { useQuery, gql } from "@apollo/client";
import { Integrator } from "types";

export function GetIntegratorById(codeId: string) {
  const {
    data = { IntegratorById: undefined },
    loading: isLoading,
    error,
  } = useQuery(GET_INTEGRATORBYID, { variables: { codeId }, skip: skip({ codeId: codeId }), });
  return { isLoading, data: data.IntegratorById as Integrator, error };
}

function skip({ codeId }: {codeId: string }) {
  let skip = true;
  skip = ![codeId].every((v) => v);
  return skip;
}

export const GET_INTEGRATORBYID = gql`
  query IntegratorById($codeId: ID!) {
    IntegratorById(codeId: $codeId) {
      _id
      name
      description
      active
      modules
      apiKey
    }
  }
`;
