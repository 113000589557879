import { useState } from "react";
import { Button } from "antd";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { PageContent, Drawer } from "components";
import { ListIntegrator, DetailIntegrator } from "../components";
import { I18n } from "i18n";

export function Integrator() {
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [integratorId, setIntegratorId] = useState<string>("")

  const onCloseDrawer = () => {
    setIntegratorId("");
    setVisible(false);
  };

  const onShowDrawer = (value?: string) => {
    if (!value) return setVisible(true);
    setIntegratorId(value);
    setVisible(true);
  };

  return (
    <PageContent
      title={I18n("list_integrator", true)}
      backIcon={false}
      extra={[
        <Button
          disabled={false}
          type="primary"
          className="rk-button-primary"
          onClick={() => history.push("/integrator/create")}
        >
          {I18n("add", true)}
        </Button>,
      ]}
    >
      <ListIntegrator onShowDrawer={onShowDrawer}></ListIntegrator>
      <Drawer
        showTitle={true}
        title={I18n("integrator_information", true, true)}
        placement="right"
        onClose={() => onCloseDrawer()}
        visible={visible}
        closeIcon={[
          <div style={{ display: "flex", gap: 30 }}>
            {integratorId && (
              <Link to={`/integrator/${integratorId}`}>
                <div>{I18n("edit", true)}</div>
              </Link>
            )}
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>,
        ]}
      >
        <DetailIntegrator integratorId={integratorId} onCloseDrawer={onCloseDrawer}></DetailIntegrator>
      </Drawer>
    </PageContent>
  );
}
